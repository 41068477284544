import moment from 'moment';

import type { TrelloWindow } from '@trello/window-types';

declare const window: TrelloWindow;

import { makeYearAwareCalendar } from './makeYearAwareCalendar';

const monthsNominative = [
  'Styczeń',
  'Luty',
  'Marzec',
  'Kwiecień',
  'Maj',
  'Czerwiec',
  'Lipiec',
  'Sierpień',
  'Wrzesień',
  'Październik',
  'Listopad',
  'Grudzień',
];
const monthsSubjective = [
  'Stycznia',
  'Lutego',
  'Marca',
  'Kwietnia',
  'Maja',
  'Czerwca',
  'Lipca',
  'Sierpnia',
  'Września',
  'Października',
  'Listopada',
  'Grudnia',
];

function plural(n: any) {
  return n % 10 < 5 && n % 10 > 1 && ~~(n / 10) % 10 !== 1;
}

function translate(num: any, withoutSuffix: any, key: any) {
  switch (key) {
    case 'm':
      return withoutSuffix ? 'minuta' : 'minutę';
    case 'mm':
      return `${num} ${plural(num) ? 'minuty' : 'minut'}`;
    case 'h':
      return withoutSuffix ? 'godzina' : 'godzinę';
    case 'hh':
      return `${num} ${plural(num) ? 'godziny' : 'godzin'}`;
    case 'MM':
      return `${num} ${plural(num) ? 'miesiące' : 'miesięcy'}`;
    case 'yy':
      return `${num} ${plural(num) ? 'lata' : 'lat'}`;
    default:
      return '';
  }
}

moment.locale(window.locale, {
  months(momentToFormat, format) {
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    if (/D MMMM/.test(format)) {
      return monthsSubjective[momentToFormat.month()];
    } else {
      return monthsNominative[momentToFormat.month()];
    }
  },
  monthsShort: [
    'sty',
    'lut',
    'mar',
    'kwi',
    'maj',
    'cze',
    'lip',
    'sie',
    'wrz',
    'paź',
    'lis',
    'gru',
  ],
  weekdays: [
    'niedziela',
    'poniedziałek',
    'wtorek',
    'środa',
    'czwartek',
    'piątek',
    'sobota',
  ],
  weekdaysShort: ['nie', 'pon', 'wt', 'śr', 'czw', 'pt', 'sb'],
  weekdaysMin: ['N', 'Pn', 'Wt', 'Śr', 'Cz', 'Pt', 'So'],
  longDateFormat: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'DD.MM.YYYY',
    LL: 'D MMMM YYYY',
    LLL: 'D MMMM YYYY HH:mm',
    LLLL: 'D MMMM',
  },
  // see `app/src/components/DateRangePicker/getLocalizedPlaceholder.ts`
  placeholder: {
    l: 'D.M.RRRR',
    LT: 'G:mm',
  },
  calendar: makeYearAwareCalendar({
    sameDay: '[Dziś o] LT',
    nextDay: '[Jutro o] LT',
    nextWeek: 'dddd [o] LT',
    lastDay: '[Wczoraj o] LT',
    lastWeek() {
      switch (this.day()) {
        case 0:
          return '[Zeszłą niedzielę o] LT';
        case 3:
          return '[Zeszłą środę o] LT';
        case 6:
          return '[Zeszłą sobotę o] LT';
        default:
          return '[Zeszły] dddd [o] LT';
      }
    },
    sameYear: 'llll [o] LT',
    sameElse: 'll [o] LT',
  }),
  relativeTime: {
    future: 'za %s',
    past: '%s temu',
    s: 'kilka sekund',
    m: translate,
    mm: translate,
    h: translate,
    hh: translate,
    d: '1 dzień',
    dd: '%d dni',
    M: 'miesiąc',
    MM: translate,
    y: 'rok',
    yy: translate,
  },
  ordinalParse: /\d{1,2}\./,
  ordinal: () => '%d.',
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4, // The week that contains Jan 4th is the first week of the year.
  },
});
